import React, { useState, useContext, useEffect, useRef } from 'react';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import classNames from 'classnames';
import { useScrollDirection } from '../../hooks/useScrollDirection';
import { motion, AnimatePresence } from 'framer-motion';
import ButtonSecondaryEllipse from '../ButtonSecondaryEllipse';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
import s from './FooterContact.module.scss';
import { trackContactInteraction } from '../../utils/dataLayer';
import {
  ContactContext,
  GradientContext,
  BackgroundContext,
} from '../../containers/BasePage';
import Link from 'next/link';

const FooterContact = ({
  titleTop,
  titleBottom,
  preamble,
  email,
  phone,
  teamMembers,
  goToForm,
}) => {
  const hasScrolledIntoView = useRef(false);
  const [inViewRef, isVisible] = useIntersectionObserver(
    undefined,
    undefined,
    true
  );
  const [inViewRefCta, isVisibleCta] = useIntersectionObserver(
    undefined,
    undefined,
    true
  );
  const [bgRef, isVisibleBg, position] = useIntersectionObserver(
    undefined,
    undefined,
    false
  );
  const [isHovered, setIsHovered] = useState(false);

  const background = useContext(BackgroundContext);
  const { toggleWhiteBackground, setHasSetBg, whiteBackground } = background;
  const gradient = useContext(GradientContext);
  const classes = classNames(
    s.Root,
    {
      [s.Visible]: isVisible,
      [s.White]: whiteBackground,
    },
    s[gradient]
  );
  const { t } = useTranslation();
  const contact = useContext(ContactContext);

  useEffect(() => {
    if (isVisibleBg) {
      toggleWhiteBackground(false);
      setHasSetBg(true);
      hasScrolledIntoView.current = true;
    } else if (
      !isVisibleBg &&
      hasScrolledIntoView.current &&
      position === 'BELOW'
    ) {
      setHasSetBg(true);
      toggleWhiteBackground(true);
    }
  }, [isVisibleBg]);

  const hasTeamMembers = !!teamMembers?.items?.length;

  return (
    <section className={classes} ref={bgRef} id="footer-contact">
      <div className={`${s.Container} grid`} ref={inViewRef}>
        {titleTop && (
          <div className={s.Tagline}>
            Contact us
            {!whiteBackground && (
              <i className={classNames(s.Gradient, s.TopLeft, s[gradient])} />
            )}
          </div>
        )}
      </div>
      <div className={s.Rel}>
        <h2 className={`${s.Title} ${s.TitleMobile} grid`}>
          <span className={s.TitleContainer}>
            <span className={s.TitleTop}>{titleTop}</span>
            <span className={s.TitleBottom}> {titleBottom}</span>
          </span>
        </h2>

        <h2 className={`${s.Title} ${s.TitleDesktop} grid`}>
          <span className={s.TitleTop}>{titleTop}</span>
          <span className={s.TitleBottom}> {titleBottom}</span>
        </h2>

        <div className={'grid'}>
          <div className={s.Preamble}>{preamble}</div>
        </div>

        {goToForm && (
          <div
            ref={inViewRefCta}
            className={classNames(s.CtaWrap, 'grid', {
              [s.CtaVisible]: isVisibleCta,
            })}>
            <div className={`${s.CtaBorder}`}>
              <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className={`${s.Cta}`}
                ref={inViewRefCta}>
                <div className={s.CtaContent}>
                  <h3 className={s.CtaText}>Send us a request</h3>
                  {!!contact?.url && (
                    <Link
                      href={contact?.url}
                      aria-hidden={true}
                      className={s.HiddenLink}>
                      Go to contact form
                    </Link>
                  )}

                  <div className={s.ActivityDuration}>
                    <Image
                      className={s.ActivityIcon}
                      width={22}
                      height={22}
                      src={`/img/clock--white.svg`}
                      alt=""
                    />

                    {t('Generic.activityDuration')}
                  </div>
                </div>
                <div className={s.CtaBtn}>
                  <ButtonSecondaryEllipse
                    asLink
                    modifiers={['InlineFlex']}
                    href={contact?.url}
                    icon="icon-arrow-right-white"
                    active={isHovered}>
                    <span className="sr-only">Go to contact form</span>
                  </ButtonSecondaryEllipse>
                </div>
              </div>
            </div>
          </div>
        )}

        {!goToForm && (
          <div className={'grid'}>
            <div
              className={classNames(s.BoxWrap, {
                [s.BoxMargin]: !hasTeamMembers,
              })}>
              {!whiteBackground && (
                <i
                  className={classNames(s.Gradient, s.MiddleRight, s[gradient])}
                />
              )}
              {email?.email && <EmailBox {...email} />}
              {phone?.phoneNumber && <PhoneBox {...phone} />}
            </div>
            {hasTeamMembers && (
              <div
                className={classNames(s.BoxWrap, {
                  [s.BoxMargin]: hasTeamMembers,
                })}>
                <TeamMembers {...teamMembers} />
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

const EmailBox = ({ email, text }) => {
  const [isOpened, setIsOpened] = React.useState(false);

  return (
    <div className={s.EmailWrap}>
      <div className={s.EmailBorder}>
        <div className={s.EmailContainer}>
          <div className={classNames(s.EmailButton, { [s.Opened]: isOpened })}>
            <h3 className={s.BoxHeading}>
              <button
                className={s.EmailButton}
                aria-expanded={isOpened}
                aria-controls="email-content"
                onClick={() => {
                  trackContactInteraction('email');
                  setIsOpened(!isOpened);
                }}>
                <span className={s.ButtonText}>Send us an email</span>
                <span className={s.SmallButtonText}>Show e-mail</span>
                <span className={s.EmailButtonIcon}>
                  <i className={s.Bg} />
                  <i
                    className={classNames(s.ExpandButton, {
                      [s.Open]: isOpened,
                    })}
                  />
                </span>
              </button>
            </h3>
          </div>
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            id="email-content"
            variants={{
              open: { height: 'auto', opacity: 1 },
              collapsed: {
                opacity: 0,
                height: 0,
                transition: {
                  height: {
                    delay: 0.3,
                  },
                },
              },
            }}
            transition={{
              duration: 0.3,
              opacity: {
                duration: 0.2,
                delay: 0.3,
              },
            }}>
            {isOpened && (
              <motion.div
                className={classNames(s.EmailContent, {
                  [s.Opened]: isOpened,
                })}
                variants={{
                  open: { height: 'auto', opacity: 1 },
                  collapsed: {
                    opacity: 0,
                    height: 0,
                    transition: {
                      height: {
                        delay: 0.3,
                      },
                    },
                  },
                }}
                transition={{
                  duration: 0.3,
                  opacity: {
                    duration: 0.2,
                    delay: 0.3,
                  },
                }}>
                <div className={s.Email}>
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
                <div className={s.Text}>{text}</div>
              </motion.div>
            )}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

const PhoneBox = ({ phoneNumber, text }) => {
  const [isOpened, setIsOpened] = React.useState(false);

  return (
    <div className={s.PhoneWrap}>
      <div className={'black-glass'}>
        <div className={s.PhoneContainer}>
          <div className={classNames(s.PhoneButton, { [s.Opened]: isOpened })}>
            <h3 className={s.BoxHeading}>
              <button
                className={s.PhoneButton}
                aria-expanded={isOpened}
                aria-controls="phone-content"
                onClick={() => {
                  trackContactInteraction('phone');
                  setIsOpened(!isOpened);
                }}>
                <span className={s.ButtonText}>Call the team</span>
                <span className={s.SmallButtonText}>Show telephone number</span>
                <span className={s.PhoneButtonIcon}>
                  <i className={s.Bg} />
                  <i
                    className={classNames(s.ExpandButton, {
                      [s.Open]: isOpened,
                    })}
                  />
                </span>
              </button>
            </h3>
          </div>
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            id="phone-content"
            variants={{
              open: { height: 'auto', opacity: 1 },
              collapsed: {
                opacity: 0,
                height: 0,
                transition: {
                  height: {
                    delay: 0.3,
                  },
                },
              },
            }}
            transition={{
              duration: 0.3,
              opacity: {
                duration: 0.2,
                delay: 0.3,
              },
            }}>
            {isOpened && (
              <motion.div
                className={classNames(s.PhoneContent, {
                  [s.Opened]: isOpened,
                })}
                variants={{
                  open: { height: 'auto', opacity: 1 },
                  collapsed: {
                    opacity: 0,
                    height: 0,
                    transition: {
                      height: {
                        delay: 0.3,
                      },
                    },
                  },
                }}
                transition={{
                  duration: 0.3,
                  opacity: {
                    duration: 0.2,
                    delay: 0.3,
                  },
                }}>
                <div className={s.Phone}>
                  <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                </div>
                <div
                  className={s.Text}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </motion.div>
            )}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

const TeamMembers = ({ items }) => {
  const [isOpened, setIsOpened] = React.useState(false);

  return (
    <div className={s.TeamMembers}>
      <h3 className={s.TeamHeading}>
        <button
          className={s.TeamButton}
          aria-expanded={isOpened}
          aria-controls="team"
          onClick={() => {
            trackContactInteraction('team');
            setIsOpened(!isOpened);
          }}>
          <span className={s.TeamButtonIcon}>
            <i className={s.Bg} />
            <i
              className={classNames(s.ExpandButton, s.Chevron, {
                [s.Open]: isOpened,
              })}
            />
          </span>
          <span className={s.TeamButtonText}>
            The team&apos;s contact details
          </span>
        </button>
      </h3>
      <motion.div
        key="content"
        initial="collapsed"
        animate="open"
        exit="collapsed"
        id="team"
        variants={{
          open: { opacity: 1 },
          collapsed: {
            opacity: 0,
          },
        }}
        transition={{
          duration: 0.3,
        }}>
        <motion.div
          className={classNames(s.TeamContent, {
            [s.Opened]: isOpened,
          })}
          variants={{
            open: { opacity: 1 },
            collapsed: {
              opacity: 0,
            },
          }}
          transition={{
            duration: 0.3,
          }}>
          {isOpened && (
            <>
              {items.map((item, index) => (
                <TeamMember key={index} {...item} />
              ))}
            </>
          )}
        </motion.div>
      </motion.div>
    </div>
  );
};

const TeamMember = ({ name, title, email, phoneNumber }) => {
  return (
    <div className={s.TeamMember}>
      <h3 className={s.TeamMemberName}>{name}</h3>
      <div className={s.TeamMemberTitle}>{title}</div>
      <div className={s.Phone}>
        <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
      </div>
      <div className={s.Email}>
        <a href={`mailto:${email}`}>{email}</a>
      </div>
    </div>
  );
};

FooterContact.propTypes = {};

FooterContact.defaultProps = {};

export default FooterContact;
